import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkImage, {
  withLazyLoading,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkPrice, {
  SIZES,
  ALIGNS,
} from '@skyscanner/backpack-web/bpk-component-price';
import BpkRating, {
  RATING_SIZES,
} from '@skyscanner/backpack-web/bpk-component-rating';
import BpkStarRating from '@skyscanner/backpack-web/bpk-component-star-rating';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { InventoryCardProps } from '@skyscanner-internal/falcon-common-types/types/InventoryCardProps';

import STYLES from './InventoryCard.module.scss';

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);
const IMG_WIDTH = 392;
const IMG_HEIGHT = 116;

const ratingDetails = (rating: {
  ratingKey?: string | undefined;
  title?: string | undefined;
  tripAdvisorScoreImageUrl: any;
  value: any;
  label?: string;
  subtitle?: string;
}) => {
  if (rating.tripAdvisorScoreImageUrl !== '') {
    return (
      <img
        alt="Tripadvisor"
        className={STYLES.tripAdvisorImage}
        src={rating.tripAdvisorScoreImageUrl}
      />
    );
  }
  if (rating.ratingKey) {
    return rating.ratingKey;
  }
  return null;
};

const InventoryCard = ({
  distanceFromCentre,
  hotelStars,
  hotelStarsAltText,
  href,
  imageAltText,
  imageSrc,
  noFollow = false,
  onClick,
  price,
  priceTrailingText,
  rating,
  title,
}: InventoryCardProps) => (
  <div className={STYLES.InventoryCardWrapper}>
    <BpkCard
      rel={noFollow ? 'nofollow' : undefined}
      padded={false}
      className={STYLES.InventoryCard}
      href={href}
      onClick={onClick}
    >
      <div className={STYLES.hotelImage}>
        <LazyLoadedImage
          aspectRatio={IMG_WIDTH / IMG_HEIGHT}
          altText={imageAltText}
          src={imageSrc?.replace('_WxH', `_${IMG_WIDTH}x${IMG_HEIGHT}`)}
        />
      </div>
      <div className={STYLES.cardInfo}>
        <div className={STYLES.hotelInfo}>
          <div className={STYLES.hotelNameAndRating}>
            <BpkText
              className={STYLES.hotelTitle}
              tagName="span"
              textStyle={TEXT_STYLES.heading4}
            >
              {title}
            </BpkText>
            {hotelStars > 0 && (
              <BpkStarRating
                className={STYLES.starRating}
                ratingLabel={hotelStarsAltText}
                rating={hotelStars}
              />
            )}
          </div>
          {distanceFromCentre && (
            <div className={STYLES.distanceFromCentre}>
              <BpkText tagName="span" textStyle={TEXT_STYLES.footnote}>
                {distanceFromCentre}
              </BpkText>
            </div>
          )}
          {rating?.reviewCount !== 0 && (
            <div className={STYLES.tripAdvisorRatingReviews}>
              <BpkRating
                className={STYLES.tripAdvisorRating}
                ariaLabel={rating.label}
                value={rating.value}
                title={ratingDetails(rating)}
                subtitle={rating.subtitle}
                size={RATING_SIZES.base}
                showScale={rating.tripAdvisorScoreImageUrl === ''}
              />
            </div>
          )}
        </div>
        <div className={STYLES.hotelPrice}>
          <BpkPrice
            className={STYLES.price}
            price={price}
            size={SIZES.small}
            align={ALIGNS.right}
            trailingText={priceTrailingText}
          />
        </div>
      </div>
    </BpkCard>
  </div>
);
export default InventoryCard;
